// import { getCookie } from '@shein/common-function'

function getCommonParams () {
  const url = window.location.href
  const { langPath, lang } = gbCommonInfo
  return { url, lang, langPath }
}

const ERROR_REPORT_CONFIG = {
  REQUIRED_PARAMS: [
    'payment_error_scene', 
    'failure_type', 
    'product_type',
    'client_url'
  ],
  BUSINESS_REQUIRED_PARAMS: [
    'business_info_scene'
    // 'product_type',
    // 'checkout_type'
  ],
  BUSINESS_SERVICE_TYPE_LIST: [
    'error_payment_failed', // 支付线失败
    'error_checkout_failed' // 下单线失败
  ],
  FAILURE_TYPE_LIST: [
    'web', // 前端代码逻辑报错
    'sdk', // sdk报错
    'api' // 接口报错
  ],
  PRODUCT_TYPE_LIST: [
    'normal', // 普通商品
    'gift_card', // 礼品卡
    'paid_membership', // 付费会员
    'paid_shein_saver', // 超省卡
    'one_click_pay' // 一键购
  ],
  CHECKOUT_TYPE_LIST: [
    'normal', // 普通商品一次下单
    'normal_again', // 普通商品二次下单
    'giftcard', // 礼品卡一次下单
    'giftcard_again', // 礼品卡二次下单
    'buyprime', // 付费会员一次下单
    'buyprime_again', // 付费会员二次下单
    'xtra', // 超省卡一次下单
    'xtra_again', // 超省卡二次下单
    'ocp', // 一键购
    'front_token', // token前置
    'buynow', // 立即购一次下单
    'buynow_again', // 立即购二次下单
  ],
  PAYMENT_ACTION_TYPE_LIST: [
    'card', // 支付失败
    'redirect_third', // 跳转第三方支付
    'redirect_self_page', // 跳转自有支付页面
    'paypal_sdk', // paypal sdk
    'venmo_sdk', // venmo sdk
    'apple_pay', // apple pay
    'google_pay', // google pay
    'cod', // 货到付款
    'channel_sdk' // 调用渠道sdk支付方式（除pp及venmo之外的）
  ],
}

const paramValueMap = {
  failure_type: ERROR_REPORT_CONFIG.FAILURE_TYPE_LIST,
  product_type: ERROR_REPORT_CONFIG.PRODUCT_TYPE_LIST,
  checkout_type: ERROR_REPORT_CONFIG.CHECKOUT_TYPE_LIST,
  payment_action_type: ERROR_REPORT_CONFIG.PAYMENT_ACTION_TYPE_LIST
}

// wiki.pageId=1289665957
/**
 * ex: 
 * import errorReport from '@/public/src/pages/common/handlePay/log'
 * 
 * errorReport.sendPaymentError('channel_session_req_amount_empty', {
 *  failure_type: 'web', // 
 *  product_type: 'normal',
 *  payment_action_type: 'paypal_sdk',
 *  client_url: '/web/error',
 *  error_data: {
 *    order_no: 'xxxxxx', // 订单号，非必填
 *    business_model: '1', 商品业务模式  非必填
 *    buy_tp: 1, // 随单购类型(超省卡,付费会员),传值同bi埋点 非必填
 *    description: '创建渠道session时未获取到amount', // 自定义错误描述 非必填
 *  }
 * })
 *
 * @class PaymentErrorReport
 */
class PaymentErrorReport {
  reportUrl = ''
  APP_NAME = 'shein_PWA_new'

  constructor () {
    // this.reportUrl = this._getErrorReportUrl()
  }

  _verifyErrorInfo (errorInfo = {}) {
    for (const key of ERROR_REPORT_CONFIG.REQUIRED_PARAMS) {
      if (!errorInfo[key]) throw new Error(`${key} is required`)
      if (paramValueMap[key] && !(paramValueMap[key].includes?.(errorInfo?.[key]))) {
        console.warn('Trade Log=>', `"${errorInfo[key]}" is not defined in "${key}" map, please confirm or pre -define first`)
      }
    }
  }

  _verifyBusinessInfo (businessInfo = {}) {
    for (const key of ERROR_REPORT_CONFIG.BUSINESS_REQUIRED_PARAMS) {
      if (!businessInfo[key]) throw new Error(`${key} is required`)
      if (paramValueMap[key] && !(paramValueMap[key].includes?.(businessInfo[key]))) {
        console.warn('Trade Log=>', `"${businessInfo[key]}" is not defined in "${key}" map, please confirm or pre -define first`)
      }
    }
  }

  _getErrorReportUrl () {
    const { REPORT_URL = {} } = gbCommonInfo ?? {}
    // REPORT_URL.SA_REPORT_URL = 'https://www.srmdata.com'
    const { SA_REPORT_URL = '' } = REPORT_URL
    return `${SA_REPORT_URL}/unusual`
  }

  _getCommonReportParams () {
    const {
      IS_RW
    } = typeof window !== 'undefined' ? window.gbCommonInfo || {} : {}
    return {
      sys_name: IS_RW ? 'romwe_m' : 'shein_pwa'
    }
  }

  _sendError (payment_error_scene, errorInfo = {}) {
    if (typeof window == 'undefined') return
    if (!window.ErrorJs) return
    try {
      this._verifyErrorInfo({ ...errorInfo, payment_error_scene })
      // window.ErrorJs.init({
      //   id: 1,
      //   envFilter: [],
      // })
      this.reportUrl = this._getErrorReportUrl()
      window.ErrorJs.sendError('error', this.reportUrl, { 
        server_name: 'skynet',
        payment_error_scene,
        appName: this.APP_NAME,
        reportList: [
          {
            metricName: 'web_payment_error_total',
            error_scene: payment_error_scene,
            product_type: errorInfo?.product_type || '',
            failure_type: errorInfo?.failure_type || '',
            business_service_type: errorInfo?.business_service_type || '',
            status_code: String(errorInfo?.error_data?.status_code || ''),
            sub_site: gbCommonInfo?.SiteUID,
            // sys_name: errorInfo?.sys_name || '',
          }
        ],
        error_type: 'script_error',
        ...getCommonParams(),
        ...errorInfo
      })
    } catch (error) {
      console.error(error)
    }
  }
  /**
   * 支付线异常上报
   *
   * @param {string} [payment_error_scene=''] 自定义异常场景key
   * @param {*} [errorInfo={}] 异常信息
   * @memberof PaymentErrorReport
   */
  sendPaymentError (payment_error_scene = '', errorInfo = {}) {
    errorInfo = Object.assign({}, {
      business_service_type: 'error_payment_failed',
      ...this._getCommonReportParams()
    }, errorInfo)
    this._sendError(payment_error_scene, errorInfo)
  }
  /**
   * 下单线异常上报
   *
   * @param {*} [payment_error_scene=''] 自定义异常场景key
   * @param {*} [errorInfo={}] 异常信息
   * @memberof PaymentErrorReport
   */
  sendCheckoutError (payment_error_scene = '', errorInfo = {}) {
    errorInfo = Object.assign({}, {
      business_service_type: 'error_checkout_failed',
      ...this._getCommonReportParams()
    }, errorInfo)
    this._sendError(payment_error_scene, errorInfo)
  }

  sendBusinessInfo (business_info_scene, businessInfo = {}) {
    if (typeof window == 'undefined') return
    if (!window.ErrorJs) return
    try {
      businessInfo = Object.assign({}, this._getCommonReportParams(), businessInfo)
      this._verifyBusinessInfo({ ...businessInfo, business_info_scene })
      this.reportUrl = this._getErrorReportUrl()
      window.ErrorJs?.sendError?.('error', this.reportUrl, {
        business_info_scene,
        appName: this.APP_NAME,
        reportList: [
          {
            metricName: 'fe_trade_business_scene_total',
            business_scene: business_info_scene,
            product_type: businessInfo?.product_type || '',
            checkout_type: businessInfo?.checkout_type || '',
            extra_scene: businessInfo?.extra_scene || '',
            sys_name: businessInfo?.sys_name || '',
          }
        ],
        error_type: 'script_error',
        ...getCommonParams(),
        ...businessInfo
      })
    } catch (error) {
      console.error(error)
    }
  }
}

export default new PaymentErrorReport()
